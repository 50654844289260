.icon-arrow {
    content: '';
    display: flex;
    height: 30px;
    width: 30px;
    border: 1px solid white;
    border-radius: 50%;
    position: relative;
    justify-content: center;
    transition: all 0.35s $primary-ease;

    &:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 1px;
        background-color: white;
        top: 50%;
        transition: all 0.35s $primary-ease;
        align-self: center;
        transform-origin: right;
    }

    &:after {
        content: "";
        border-bottom: 1px solid white;
        border-right: 1px solid white;
        width: 6px;
        height: 6px;
        transform: rotate(-45deg);
        transform-origin: center;
        top: calc(50% - 3px);
        left: calc(50% - 5px);
        align-self: center;
        margin-left: -3px;
        transition: all 0.35s $primary-ease;
    }

    &.vertical {
        border: 0;
        transform: rotate(90deg);

        &:before {
            width: 13px;
        }

        &:after {
            margin-left: -7px;
        }
    }

    &.reverse {
        transform: rotate(180deg);
    }
}

.icon-round {
    display: inline-block;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 1px solid $color-gray-blue;

    &.principles {
        @include retina('/img/common/icon_shield.png', 2, 21px 24px, center center no-repeat);
    }

    &.resources {
        @include retina('/img/common/icon_palace.png', 2, 24px 25px, center center no-repeat);
    }

    &.practice {
        @include retina('/img/common/icon_checklist.png', 2, 18px 24px, center center no-repeat);
    }

    &.graph {
        @include retina('/img/common/icon_graph.png', 2, 24px 24px, center no-repeat);
    }

    &.return {
        @include retina('/img/common/icon_return.png', 2, 22px 25px, center no-repeat);
    }

    &.connect {
        @include retina('/img/common/icon_connect.png', 2, 24px 24px, center no-repeat);
    }

    &.hand {
        @include retina('/img/common/icon_hand.png', 2, 24px 23px, center no-repeat);
    }

    &.network {
        @include retina('/img/common/icon_network.png', 2, 24px 24px, center no-repeat); 
    }

    &.head-fig {
        @include retina('/img/common/icon_head.png', 2, 22px 24px, center no-repeat);
    }

    &.star {
        @include retina('/img/common/icon_star.png', 2, 24px 24px, center no-repeat);
    }

    &.checklist-bright {
        @include retina('/img/common/icon_checklist_bright.png', 2, 18px 24px, center no-repeat);
    }

    &.heart {
        @include retina('/img/common/icon_heart.png', 2, 24px 24px, center no-repeat);
    }

    &.small {
        width: 33px;
        height: 33px;
    }

    &.gray-border {
        border-color: $color-gray;
    }

    &.green-border {
        border-color: #607d8b;
    }

    &.check {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:after {
            content: '';
            display: block;
            width: 13px;
            height: 5px;
            border-top: 1px solid white;
            border-right: 1px solid white;
            align-self: center;
            transform: rotate(135deg);
            transform-origin: center 2px;
        }
    }
}