.page-portfolio {
    position: relative;

    .list {
        padding-top: 70px;
        
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        li {
            width: 667px;
            margin-bottom: 70px;

            @media ($tablet-screen) {
                width: 100%;
                margin: 0 auto 70px;
            }

            @media ($mobile-screen) {
                width: 100%;
                margin-bottom: 40px;
            }
        }
    }
}

.page-portfolio-detail {

    .content {
        display: flex;
        margin-top: 120px;

        @media ($device-screen) {
            flex-direction: column;
            margin-top: 40px;
        }

        .context {
            margin-right: 70px;

            @media ($device-screen) {
                margin-right: 0;
                margin-bottom: 40px;
            }
        }

        h2 {
            margin-bottom: 70px;
        }
    }

    .gallery {
        position: relative;
        width: 700px;
        height: 400px;

        @media ($device-screen) {
            width: 100%;
            height: 400px;
        }
    }

    .map {
        width: 100%;
        height: 520px;
        margin: 100px auto;

        @media ($mobile-screen) {
            height: 300px;
            margin: 100px auto 40px;
        }
    }

    .portfolio-link {
        ul {
            display: flex;
            justify-content: space-between;

            @media ($device-screen) {
                flex-wrap: wrap;
            }
        }

        .item-portfolio {
            width: 667px; 
            margin-bottom: 100px;

            @media ($device-screen) {
                margin-bottom: 40px;
            }
        }
    }
}