header {
    display: flex;
    width: 100%;
    height: 160px;
    will-change: transform;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.55s $primary-ease;
    z-index: 12;

    @media ($mobile-screen) {
        height: 120px;
    }

    .logo-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 100px;

        @media ($mobile-screen) {
            padding-left: 25px;
        }

        @media ($tablet-screen) {
            padding-left: 50px;
        }

        .white,
        .white-large {
            display: none;
        }
    }

    &.white-large {
        .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
            background-color: white;
        }

        .logo-container {
            .blue {
                display: none;
            }

            .white-large {
                display: inline-block;
            }
        }
    }

    &.show-background {
        background-color: $color-primary;
        height: 100px;

        .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
            background-color: white;
        }

        .logo-container {
            .blue {
                display: none;
            }

            .white-large {
                display: none;
            }
            
            .white {
                display: inline-block;
            }
        }

        .resources {
            color: white;

            a:first-of-type:after {
                background-color: rgba(white, 0.5);
            }
        }
    }

    &.prevent-pointer {
        pointer-events: none;

        .hamburger {
            pointer-events: all;
        }
    }

    .hamburger-container {
        flex: 1;
        align-self: center;
        text-align: right;
        margin-right: 100px;
    
        @media ($mobile-screen) {
            margin-right: 25px;
        }

        @media ($tablet-screen) {
            margin-right: 50px;
        }
    }
}

.mobile-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    top: 0;
    right: 0;
    background-color: $color-primary;
    z-index: 11;
    color: white;
    box-sizing: border-box;
    transition: transform 0.55s $primary-ease;

    .logo-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 30px;
    }

    nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;

        @media ($mobile-screen) {
            height: calc(100% - 70px);
            margin-top: 70px;
        }

        ul {
            display: flex;
            width: 100%;
            height: 100%;

            @media ($device-screen) {
                flex-direction: column;
            }
        }

        li {
            width: 25%;
            height: 100%;
            font-size: 30px;
            text-align: center;
            position: relative;

            @media ($device-screen) {
                width: 100%;
                height: 25%;
            }

            &.people {
                background: url('/img/common/nav_bg_property.jpg') center/cover no-repeat;
            }

            &.portfolio {
                background: url('/img/common/nav_bg_company.jpg') center/cover no-repeat;
            }

            &.about {
                background: url('/img/common/nav_bg_history.jpg') center/cover no-repeat;
            }

            &.contact {
                background: url('/img/common/nav_bg_contact.jpg') center/cover no-repeat;
            }

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: $color-blue;
                opacity: 0.9;
                transition: opacity 0.45s $primary-ease;
                z-index: 13;
            }

            a {
                @include hind-medium();
                font-size: 40px;
                color: white;
                position: relative;
                z-index: 14;
                transition: all 0.55s $primary-ease;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                outline: none;

                @media ($mobile-screen) {
                    font-size: 25px;
                }
            }

            @media ($desktop-screen) {
                &:hover {
                    &:before {
                        opacity: 0.7;
                    }
    
                    a {
                        transform: translateY(-45px);
                    }
                }
            }
        }
    }

    .menu-foot {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;

        .copyright {
            font-size: 12px;
            opacity: 0.5;
        }
    }
        
    &.hidden {
        transform: translateY(-100vh);
    }
}