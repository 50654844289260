.typography-hero {
    @include pt-regular;
    font-size: 70px;
    line-height: 70px;

    @media ($tablet-screen) {
        font-size: 70px;
        line-height: 70px;
    }

    @media ($mobile-screen) {
        font-size: 45px;
        line-height: 50px;
    }
}

.typography-head {
    @include pt-regular;
    font-size: 75px;
    line-height: 85px;

    @media ($mobile-screen) {
        font-size: 40px;
        line-height: 45px;
    }
}

.typography-h1 {
    @include pt-regular;
    font-size: 50px;
    line-height: 55px;

    @media ($mobile-screen) {
        font-size: 40px;
        line-height: 45px;
    }
}

.typography-h2 {
    @include pt-regular;
    font-size: 40px;
    line-height: 50px;

    @media ($tablet-screen) {
        font-size: 35px;
        line-height: 45px;
    }

    @media ($mobile-screen) {
        font-size: 30px;
        line-height: 40px;
    }
}

.typography-h3 {
    @include pt-regular;
    font-size: 30px;
    line-height: 40px;

    @media ($mobile-screen) {
        font-size: 25px;
        line-height: 35px;
    }
}

.typography-link {
    @include lato-light;
    font-size: 20px;
    line-height: 30px;
    color: $color-vivid-blue;

    @media ($device-screen) {
        font-size: 18px;
        line-height: 25px;
    }
}

.typography-xlarge-paragraph {
    @include lato-light;
    font-size: 45px;
    line-height: 55px;

    @media ($mobile-screen) {
        font-size: 35px;
        line-height: 45px;
    }
}

.typography-large-paragraph {
    @include lato-light;
    font-size: 25px;
    line-height: 30px;

    @media ($mobile-screen) {
        font-size: 20px;
        line-height: 25px;
    }
}

.typography-medium-paragraph {
    @include lato-light;
    font-size: 25px;
    line-height: 30px;

    @media ($device-screen) {
        font-size: 20px;
        line-height: 25px;
    }
}

.typography-medium-paragraph-2 {
    @include lato-light;
    font-size: 25px;
    line-height: 35px;
}

.typography-small-paragraph {
    @include lato-light;
    font-size: 20px;
    line-height: 30px;

    @media ($device-screen) {
        font-size: 18px;
        line-height: 25px;
    }
}

.typography-small-text {
    @include hind-medium;
    font-size: 12px;
    text-transform: uppercase;
}

.typography-tag {
    @include lato-bold;
    font-size: 15px;
    text-transform: uppercase;
    color: rgba(white, 0.25);
}