.page-index {

    .hero {
        height: 100vh;
        color: white;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        opacity: 1;

        @media ($mobile-screen) {
            background: url('/img/homepage/shot.png') center / cover no-repeat;
        }

        video {
            position: absolute;
            left: 0;
            top: 0;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: -1;
        }

        .cover-layer {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba($color-blue-variation-5, 0.5);

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: $color-blue;
                transition: all 1.25s $secondary-ease;
            }
        }
        
        &.animated {
            .cover-layer {
                &:after {
                    transform: translateX(100%);
                }
            }
        }

        .upper {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-top: 50px;
            justify-content: center;
            position: relative;

            & > span {
                margin-top: 30px;
                width: 600px;

                @media ($mobile-screen) {
                    width: 100%;
                }
            }

            @media ($tablet-screen) {
                & > span {
                    width: 600px;
                }
            }

            @media ($mobile-screen) {
                flex: 2;
            }
        }

        h1 {
            margin-top: 92px;
            margin-bottom: 5px;
        }

        .button-default {
            margin-top: 30px;
            align-self: center;
        }

        .lower {
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @media ($mobile-screen) {
                display: none;
            }
        }

        .scroll-down {
            align-self: center;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            z-index: 10;

            @media ($tablet-screen) {
                margin-bottom: 55px;
            }

            @media ($mobile-screen) {
                margin-bottom: 50px;
            }

            .icon-arrow {
                align-self: center;
            }
        }
    }

    & > .portfolio {
        min-height: 850px;
        display: flex;
        position: relative;
        opacity: 1;
        overflow: hidden;
        margin-top: 110px;
        margin-bottom: 25px;

        @media ($device-screen) {
            flex-direction: column;
        }

        @media ($mobile-screen) {
            margin-top: 20px;
        }

        .info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 40px;
            box-sizing: border-box;
            height: 380px;

            @media ($device-screen) {
                text-align: center;
            }

            @media ($tablet-screen) {
                padding: 0;
            }

            @media ($mobile-screen) {
                padding: 0;
                text-align: left;
            }
        }

        .info .button-default {
            margin-top: 40px;
            text-align: center;

            @media ($tablet-screen) {
                align-self: center;
            }
        }

        .blocks {
            display: flex;
            flex-wrap: wrap;

            li {
                width: calc(50% - 32.5px);

                @media ($mobile-screen) {
                    width: 100%;
                }

                &:nth-of-type(odd) {
                    margin-right: 65px;

                    @media ($mobile-screen) {
                        margin: 0;
                    }
                }
            }

            .item-portfolio {
                margin-bottom: 85px;

                @media ($mobile-screen) {
                    margin-bottom: 40px;
                }
            }
        }

    }

    .cta-properties {
        height: 185px;
        background-color: #f3f3f3;
        display: flex;

        @media ($mobile-screen) {
            flex-direction: column;
            height: auto;
            padding: 50px 25px;
        }

        @media ($tablet-screen) {
            height: auto;
            padding: 50px;
        }

        & > * {
            align-self: center;

            @media ($mobile-screen) {
                align-self: left;
            }
        }

        h2 {
            &:after {
                display: inline-block;
                content: '';
                width: 1px;
                height: 30px;
                background-color: rgba(#404040, 0.3);
                vertical-align: middle;
                margin: 0 0 3px 60px;

                @media ($mobile-screen) {
                    display: none;
                }

                @media ($tablet-screen) {
                    margin: 0 0 3px 30px;
                }
            }
        }

        span {
            flex: 3;
            padding-left: 60px;
            font-size: 22px;
            line-height: 28px;

            @media ($mobile-screen) {
                padding: 20px 0 40px;
            }

            @media ($tablet-screen) {
                padding-left: 30px;
            }
        }

        .button-container.device-only {
            margin-top: 20px;
        }

        & > .button-container {
            @media ($device-screen) {
                display: none;
            }
        }

        .button-underline {
            display: inline-block;

            &:after {
                background-color: black;
            }
        }
    }
}