.bx-viewport {
    height: 500px;

    img {
        width: 700px !important;
        height: 400px !important;
    }
}

.bx-controls {
}

.bx-controls-direction {
    display: flex;
    justify-content: center;

    .icon-arrow {

        &.hover {
            background-color: white;
            
            &:before {
                width: 13px;
                background-color: $color-vivid-blue;
            }
            
            &:after {
                margin-left: -7px;
                border-color: $color-vivid-blue;
            }
        }

        &:hover {
            background-color: $color-blue;
            border-color: $color-blue;

            &:before {
                background-color: white;
            }

            &:after {
                border-color: white;
            }
        }
    }
}

.bx-pager {
    width: 100%;
    display: flex;
    justify-content: center;

    &-item {

        a {
            background-color: white;
            text-indent: -9999px;
            display: inline-block;
            width: 8px;
            height: 8px;
            margin: 0 5px;
            border-radius: 50%;
            border: 1px solid rgba(#404040, 0.7);
            outline: 0;
            transition: all 0.55s $primary-ease;
            vertical-align: middle;
            box-sizing: border-box;

            &.active {
                background-color: $color-blue;
                border-color: $color-blue;
            }
        }
    }
}

.button-carousel-toggle {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    color: white;
    @include pt-regular;
    font-size: 50px;
    display: flex;
    transition: opacity 0.55s $primary-ease;
    z-index: 3;
    padding: 0 20px;

    &.prev {
        left: 0;
        right: unset;
    }

    & > span {
        align-self: center;
    }

    @media ($desktop-screen) {
        &:hover {
            opacity: 1;
        }
    }
}