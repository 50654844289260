.item-portfolio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 380px;
    max-width: 667px;
    color: white;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
    transition: all 0.55s $primary-ease;
    position: relative;
    overflow: hidden;

    @media ($tablet-screen) {
        width: 100%;
        height: 325px;
    }

    @media ($mobile-screen) {
        width: 100%;
        height: 220px;
    }

    h3 {
        @include lato-bold;
        font-size: 20px;
        text-transform: uppercase;

        @media ($mobile-screen) {
            font-size: 15px;
        }
    }

    &.animate {
        opacity: 1;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: $color-blue;
            transition: transform 0.75s $secondary-ease;
        }
    }

    &.animated {
        &:after {
            transform: translateX(100%);
        }
    }
    
    .image {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        z-index: -1;
        transition: all 10s $gradual-ease;
        top: 0;
        left: 0;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(to top, rgba(#2d323d, 0.8), rgba(#2d323d, 0.7), rgba(#2d323d, 0.3));
            top: 0;
            left: 0;
            right: 0;
        }
    }

    .button-container {
        position: absolute;
        bottom: 25px;
        left: 0;
        right: 0;

        .button-underline {
            display: inline-block;
            transition: color 0.55s $primary-ease;

            &:after {
                transition: background-color 0.55s $primary-ease;
            }
        }
    }
    
    .color-layer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        background-color: white;
        transition: height 0.55s $primary-ease;
    }

    &:hover {

        .image {
            transform: scale(1.1);
        }

        .color-layer {
            height: 75px;
        }

        .button-underline {
            color: $color-blue;

            &:after {
                background-color: $color-blue;
            }
        }
        
    }

    &.dark {
        .image {
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: $color-dark-blue;
                transition: opacity 0.55s $primary-ease;

                @media ($device-screen) {
                    opacity: 0.5;
                }
            }
        }

        h3, .location {
            transform: translateY(20px);
            transition: transform 0.55s $primary-ease;

            @media ($device-screen) {
                transform: translateY(0);
            }
        }
    
        .button-default {
            opacity: 0;
            align-self: center;
            transform: translateY(20px);
            margin-top: 20px;

            @media ($device-screen) {
                opacity: 1;
                transform: translateY(0);
            }
        }

        &:hover {
            h3, span {
                transform: translateY(0);
            }
    
            .button-default {
                opacity: 1;
                transform: translateY(0);
            }
    
            .image {
                &:after {
                    background-color: $color-blue-variation-5;
                    opacity: 0.9;
                }
            }
        }
    }
}

.cta-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-vivid-blue;
    height: 150px;

    .button-default {
        align-self: center;
    }
}

.line-break {
    width: 100%;
    height: 1px;
    background-color: rgba(white, 0.25);
}

.cta-common {
    .column-links {
        display: flex;
        color: white;

        @media ($device-screen) {
            flex-direction: column;
        }
    }

    h2 {
        transform: translateY(50px);
        transition: transform 0.55s $primary-ease;

        @media ($device-screen) {
            transform: translateY(0);
        }
    }

    .text-container {
        align-self: center;
        text-align: center;
    }

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: all 10s $gradual-ease;
        top: 0;
        left: 0;
        right: 0;

        &.people {
            @include retina('/img/homepage/cta_people_bg.jpg', 2, cover, center no-repeat);
        }

        &.portfolio {
            @include retina('/img/homepage/cta_portfolio_bg.jpg', 2, cover, center no-repeat);
        }

        &.about {
            @include retina('/img/homepage/cta_about_bg.jpg', 2, cover, center no-repeat);
        }
    }

    .description {
        visibility: hidden;
        opacity: 0;
        transition: all 0.55s $primary-ease;
        transform: translateY(20px);
        display: block;

        @media ($device-screen) {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            margin-top: 10px;
        }
    }

    .button-default {
        visibility: hidden;
        opacity: 0;
        align-self: center;
        margin-top: 50px;
        transition: all 0.55s $primary-ease;
        transform: translateY(10px);

        @media ($device-screen) {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            margin-top: 0;
        }

        @media ($mobile-screen) {
            margin-top: 30px;
        }
    }        

    li {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        position: relative;
        height: 450px;
        overflow: hidden;

        @media ($tablet-screen) {
            height: 270px;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 50px;
        }

        &:hover {

            .background {
                transform: scale(1.1);
            }

            h2 {
                transform: translateY(0);
            }

            .description {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }

            .button-default {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }
   
}

.animate {
    transition: all 0.85s $primary-ease;
    opacity: 0;
    
    &.from-bottom {
        transform: translateY(50px);
    }

    &.from-top {
        transform: translateY(-50px);
    }

    &.delay-1 {
        transition-delay: 0.05s;
    }

    &.delay-2 {
        transition-delay: 0.1s;
    }

    &.delay-3 {
        transition-delay: 0.15s;
    }

    &.animated {
        opacity: 1;
        transform: translateY(0);
    }
}

.device-only {
    @media ($desktop-screen) {
        display: none !important;
    }
}

.swipe-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 1;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: all 0.85s $secondary-ease;
        background-color: white;
    }

    &.blue {
        &:after {
            background-color: $color-blue;
        }
    }

    &.animated {
        &:after {
            transform: translateX(100%);
        }
    }
}