@import "normalize/import-now";
@import "retina.scss";

$hamburger-padding-x: 0px;
$hamburger-padding-y: 0px;
$hamburger-layer-width: 25px;
$hamburger-layer-height: 2px;
$hamburger-layer-color: white;
$hamburger-hover-transition-duration: 0.55s;
$hamburger-hover-transition-timing-function: $primary-ease;
$hamburger-hover-opacity: 1;
$hamburger-types: (slider);

.hamburger {
    .hamburger-inner {
        transition: width 0.45s $primary-ease;
    
        &:after,
        &:before {
            transition: width 0.45s $primary-ease;
        }
    }

    &:hover:not(.is-active) {
        .hamburger-inner {
            width: 15px;
    
            &:after {
                width: 20px;
            }
        }
    }
}

@import "hamburgers";