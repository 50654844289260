.page-history {
    .history {
        background-color: $color-light-gray;
        padding-top: 110px;
        padding-bottom: 110px;

        .year {
            @include hind-bold;
            font-size: 100px;
            line-height: 1;
            color: #404040;
            margin-bottom: 10px;

            @media ($mobile-screen) {
                font-size: 60px;
            }
        }

        .description {
            @include lato-light;
            font-size: 20px;
            line-height: 25px;
            display: inline-block;

            @media ($mobile-screen) {
                font-size: 18px;
                line-height: 22px;
                max-width: 100% !important;
                box-sizing: border-box;
            }
        }

        .part-column {
            display: flex;

            @media ($device-screen) {
                flex-direction: column;
            }
        }

        .left,
        .right {
            width: 50%;

            @media ($device-screen) {
                width: 100%;
            }
        }

        .info {
            background-color: $color-light-gray;
            padding: 30px 50px;

            @media ($mobile-screen) {
                padding: 15px 25px 40px;
                transform: translate(0) !important;
            }
        }

        .image-container {
            position: relative;
            display: inline-block;
            line-height: 0;

            @media ($mobile-screen) {
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-color: $color-blue;
                transition: width 0.85s $secondary-ease;
            }

            &.animated {
                &:after {
                    width: 0%;
                }
            }
        }

        .part1 {
            .left {
                padding-left: 160px;

                .info {
                    transform: translate(70px, -70px);

                    @media ($tablet-screen) {
                        width: 500px;
                    }
                }

                .description {
                    max-width: 340px;
                }
            }

            .right {
                padding: 370px 230px 0 0; 
                text-align: right;

                .info {
                    transform: translate(-70px, -70px);
                    text-align: right;
                }

                .description {
                    max-width: 390px;
                }
            }
        }

        .part2 {
            .single {
                padding-left: 250px;

                .description {
                    max-width: 315px;
                }
            }
        }

        .part3 {
            .single {
                padding-right: 200px;
                text-align: right;

                .info {
                    transform: translate(-70px, -70px);
                    text-align: right;
                }

                .description {
                    max-width: 420px;
                }
            }
        }

        .part4 {
            .single {
                padding-left: 290px;

                .description {
                    max-width: 400px;
                }
            }
        }

        .part5 {
            display: flex;
            justify-content: flex-end;

            .single {
                padding-right: 280px;
                text-align: right;
                
                .info {
                    transform: translate(-70px, -70px);
                    text-align: left;
                }

                .description {
                    max-width: 400px;
                }
            }
        }

        .part6 {
            .single {
                padding-left: 300px;

                .info {
                    transform: translate(70px, -70px);

                    @media ($tablet-screen) {
                        width: 400px;
                    }
                }

                .description {
                    max-width: 650px;
                }
            }
        }

        .part7 {
            .image-container {
                @media ($tablet-screen) {
                    width: 100%;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .single {
                padding-right: 90px;
                text-align: right;

                .info {
                    transform: translate(-70px, -70px);
                }

                .description {
                    max-width: 660px;
                }
            }
        }

        .part8 {
            .single {
                padding-left: 155px;

                .description {
                    max-width: 480px;
                }
            }
        }

        .part9 {
            .single {
                padding-right: 300px;
                text-align: right;

                .description {
                    max-width: 480px;
                }
            }
        }

        .part10 {
            margin-top: 100px;

            .single {
                padding-left: 300px;

                .description {
                    max-width: 480px;
                }
            }
        }

        .part11 {
            display: flex;
            justify-content: flex-end;
            margin: 80px 0 110px;

            .single {
                padding-right: 265px;

                .info {
                    max-width: 380px;
                }
            }
        }

        .part12 {
            .single {
                padding-left: 180px;

                .description {
                    max-width: 480px;
                }
            }
        }

        .part13 {
            .single {
                padding-right: 215px;
                text-align: right;

                .description {
                    max-width: 410px;
                }
            }
        }

        .left,
        .right,
        .single {
            @media ($device-screen) {
                padding: 0 !important;
            }
        }
    }
}