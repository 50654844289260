.button-default {
    @include hind-semibold;
    font-size: 15px;
    text-transform: uppercase;
    line-height: 45px;
    display: flex;
    height: 45px;
    background-color: $color-blue;
    border-radius: 23px;
    align-self: left;
    padding-left: 30px;
    transition: all 0.55s $primary-ease;
    color: white;
    overflow: hidden;
    position: relative;

    @media ($mobile-screen) {
        padding-left: 20px;
    }

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(white, 0), rgba(white, 0.4), rgba(white, 0));
        left: -100%;
        top: 0;
        transition: all 0.55s $primary-ease;
    }

    .icon-arrow {
        margin: 0px 7px 0 30px;
        align-self: center;

        @media ($mobile-screen) {
            margin: 0px 7px 0 20px;
        }
    }

    &:hover {
        &:before {
            transform: translateX(200%);
        }

        .icon-arrow {
            background-color: white;

            &:before {
                width: 13px;
                background-color: $color-vivid-blue;
            }
    
            &:after {
                margin-left: -7px;
                border-color: $color-vivid-blue;
            }
        }
    }

    &.transparent {
        background-color: transparent;
        @include box-shadow(0, 0, 0, 0, transparent);
    }
}

.button-login {
    @include pt-regular;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 150px;
    height: 100%;
    color: white;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $color-vivid-blue;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 0;
        background-color: white;
        top: 0;
        left: 0;
        right: 0;
        transition: height 0.55s $primary-ease;
        z-index: -1;
    }

    &:hover {
        color: $color-vivid-blue;

        &:after {
            height: 100%;
        }
    }
}

.button-text {
    @include pt-regular;
    font-size: 15px;
    text-transform: uppercase;
    color: $color-vivid-blue;
    align-self: center;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: $color-vivid-blue;
    }
}

.button-underline {
    @include hind-medium;
    font-size: 15px;
    text-transform: uppercase;
    align-self: center;
    transition: all 0.55s $primary-ease;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: white;
        margin-top: -3px;
        transition: background-color 0.55s $primary-ease;
    }

    &:hover {
        color: $color-blue;

        &:after {
            background-color: $color-blue !important;
        }
    }
}