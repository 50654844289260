footer {
    width: 100%;
    height: 230px;
    background-color: $color-dark-blue;
    display: flex;
    color: white;
    padding: 0 100px;
    box-sizing: border-box;

    @media ($tablet-screen) {
        height: 190px;
        padding: 0 50px;
    }

    @media ($mobile-screen) {
        flex-direction: column;
        height: 368px;
        justify-content: center;
        padding: 0 40px;
    }

    .logo-container {
        align-self: center;

        @media ($tablet-screen) {
            margin-top: -31px;
        }

        @media ($mobile-screen) {
            margin-top: 50px;
        }
    }

    nav {
        @include lato-light;
        font-size: 15px;
        flex: 2;
        text-transform: uppercase;
        align-self: center;
        text-align: center;

        @media ($tablet-screen) {
            flex: 3;
            text-align: right;
        }

        ul {
            @media ($mobile-screen) {
                display: flex;
                flex-direction: column;
                margin: 20px 0;
            }
        }

        li {
            padding: 0 20px;

            @media ($tablet-screen) {
                padding: 0 10px;

                &:last-of-type {
                    padding: 0 0 0 10px;
                }
            }

            @media ($mobile-screen) {
                margin-bottom: 10px;
            }
        }

        a {
            transition: color 0.55s $primary-ease;
            
            &:hover {
                color: white;
            }
        }

        .copyright {
            display: block;
            margin-top: 10px;
            text-align: center;

            @media ($tablet-screen) {
                text-align: right;
            }
        }
    }

    .copyright {
        font-size: 13px;
        align-self: center;
        text-align: right;
        color: #94989e;
        text-transform: uppercase;

        &:not(.device-only) {
            @media ($device-screen) {
                display: none;
            }
        }
    }
}