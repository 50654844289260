.head {
    height: 400px;
    background: url('/img/common/header_bg.jpg') center / cover no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
    opacity: 1;
    color: white;

    &.bg-company {
        background: url('/img/common/header_bg_company.jpg') center / cover no-repeat;
    }

    &.bg-history {
        background: url('/img/common/header_bg_history.jpg') center / cover no-repeat;
    }

    &.bg-contact {
        background: url('/img/common/header_bg_contact.jpg') center / cover no-repeat;
    }

    &.not-found {
        height: calc(100vh - 230px);
        justify-content: center;
    }

    h1 {
        margin-bottom: 10px;
        color: white;
    }

    & > span {
        padding-bottom: 50px;
    }

    &.background {
        color: white;
        background-size: cover;

        h1 {
            color: white;
        }
    }

    &.small {
        height: 300px;

        h1 {
            // margin: 60px 0 0;
        }
    }
}

.bg-color {
    width: 100%;
    height: 100%;
    background-color: $color-primary;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
}

.band {
    min-height: 500px;
    height: 500px;
    background-color: $color-light-gray;

    @media ($device-screen) {
        height: auto;
    }

    .content {
        display: flex;
        max-width: 1222px;
        margin: 0 auto;
        height: 100%;
        
        @media ($tablet-screen) {
            flex-direction: column;
            padding: 0 50px;
        }

        @media ($mobile-screen) {
            flex-direction: column;
            margin: 0 40px;
        }
    }

    .title {
        @include pt-regular;
        font-size: 50px;
        line-height: 60px;
        color: $color-primary;
        margin-right: 120px;
        align-self: center;
        min-width: 400px;

        @media ($tablet-screen) {
            align-self: flex-start;
            margin-right: 0;
            margin-top: 50px;
        }

        @media ($mobile-screen) {
            font-size: 40px;
            line-height: 50px;
            margin: 50px 0 30px;
            align-self: flex-start;
            min-width: auto;
        }
    }

    .description {
        align-self: center;

        @media ($tablet-screen) {
            align-self: flex-start;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        @media ($mobile-screen) {
            margin-bottom: 50px;
        }
    }
}