$color-vivid-blue: #a1a7aa;
$color-dark-blue: #2c313a;
$color-blue: #a31d49;
$color-blue-variation-1: #424c84;
$color-blue-variation-2: #b4b7cb;
$color-blue-variation-3: #4c548d;
$color-blue-variation-4: #48518a;
$color-blue-variation-5: #471a29;
$color-gray-blue: #80848e;
$color-gray: #e2e2e2;
$color-light-gray: #f4f6fc;

$color-primary: $color-blue;