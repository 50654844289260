body {
    @extend .typography-small-paragraph;
}

ol, ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    display: inline-block;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    border: 0;
    background: none;
    outline: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
    padding: 0;
}

input {
    @extend .typography-small-paragraph;
}