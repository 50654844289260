@import url('https://fonts.googleapis.com/css?family=Hind+Madurai:500,600,700|Lato:300,400|PT+Serif:400');

%hind {
    font-family: 'Hind Madurai', sans-serif;
}

%pt {
    font-family: 'PT Serif', serif;
}

%lato {
    font-family: 'Lato', sans-serif;
}

@mixin hind-medium() {
    @extend %hind;
    font-weight: 500;
}

@mixin hind-semibold() {
    @extend %hind;
    font-weight: 600;
}

@mixin hind-bold() {
    @extend %hind;
    font-weight: 700;
}

@mixin pt-regular() {
    @extend %pt;
    font-weight: 400;
}

@mixin lato-light() {
    @extend %lato;
    font-weight: 300;
}

@mixin lato-bold() {
    @extend %lato;
    font-weight: 400;
}