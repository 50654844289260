.page-people {
    .department {
        height: 184px;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;

        @media ($mobile-screen) {
            height: 140px;
            margin-top: 20px;
        }
    }

    .people-grid {
        display: flex;
        flex-wrap: wrap;
        color: white;
        position: relative;
        margin-bottom: 55px;

        h2 {
            margin: 0 0 20px;

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: rgba(white, 0.25);
                margin-top: 10px;
                transition: all 0.55s $primary-ease;
            }
        }

        .image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-size: cover;
            background-position: center;
            z-index: -1;
            transition: transform 10s $gradual-ease;

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background: linear-gradient(
                    to top,
                    rgba(#2d323d, 0.5),
                    rgba(#2d323d, 0.3),
                    rgba(#2d323d, 0)
                );
                transition: background-color 0.55s $primary-ease;
            }
        }

        li {
            width: 425px;
            height: 478px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 40px;
            position: relative;
            box-sizing: border-box;
            overflow: hidden;
            margin-right: 62px;
            margin-bottom: 80px;

            &:nth-of-type(3n) {
                margin-right: 0;
            }

            @media ($tablet-screen) {
                width: calc(50% - 20px);
                height: 400px;
                margin-right: 0;
                padding: 20px;
                margin-bottom: 40px;

                &:nth-of-type(odd) {
                    margin-right: 40px;
                }
            }

            @media ($mobile-screen) {
                width: 100%;
                height: 420px;
                margin-right: 0;
                margin-bottom: 40px;
                padding: 25px;
            }

            &:hover {
                .image {
                    transform: scale(1.1);

                    &:after {
                        background-color: transparent;
                    }
                }
            }
        }

        .button-arrow {
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 6px 10px;
        }

        .button-person {
            .icon-arrow {
                width: 25px;
                height: 25px;
            }

            .typography-tag {
                transition: opacity 0.55s $primary-ease;
            }

            &:hover {
                .icon-arrow {
                    background-color: white;

                    &:before {
                        width: 13px;
                        background-color: $color-vivid-blue;
                    }

                    &:after {
                        margin-left: -7px;
                        border-color: $color-vivid-blue;
                    }
                }

                .typography-tag {
                    opacity: 0;
                }

                h2 {
                    &:after {
                        background-color: rgba(white, 0);
                        margin: 0 0 -20px;
                    }
                }
            }
        }
    }
}

.page-people-detail {
    background: $color-blue url("/img/team/detail_bg.jpg") bottom
        center no-repeat;
    background-size: cover;
    color: white;

    .detail {
        max-width: 1162px;
        margin: 0 auto;

        @media ($tablet-screen) {
            padding: 0 50px;
        }
    }

    .top {
        display: flex;
        padding-top: 200px;

        @media ($device-screen) {
            flex-direction: column;
        }

        @media ($mobile-screen) {
            padding-top: 150px;
        }
    }

    .pic {
        flex: 1;

        img {
            @include box-shadow(0, 0, 37.5px, 12.5px, rgba(black, 0.15));
        }

        @media ($mobile-screen) {
            img {
                margin: 0 40px;
                width: calc(100% - 80px);
            }
        }
    }

    .email {
        margin-top: 20px;
    }

    .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media ($tablet-screen) {
            margin-top: 50px;
        }

        @media ($mobile-screen) {
            margin: 50px 40px 0;
        }

        h1 {
            margin: 10px 0 30px;

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: rgba(white, 0.25);
                margin-top: 20px;
            }
        }
    }

    .overview {
        margin: 145px 0 0;
        padding-bottom: 150px;

        @media ($tablet-screen) {
            margin: 100px 0 0;
        }

        @media ($mobile-screen) {
            padding: 0 40px 100px;
            margin-bottom: 0;
        }
    }
}
