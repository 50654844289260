.container-with-padding {
    display: block;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 100px;
    box-sizing: border-box;

    @media ($tablet-screen) {
        padding: 0 50px;
    }
    
    @media ($mobile-screen) {
        padding: 0 25px;
    }
}

.container-with-padding-no-limit {
    @extend .container-with-padding;
    max-width: unset;
}

.container-full {
    display: block;
    width: 100%;
}