.page-contact {
    .contact {
        min-height: 600px;
        height: 600px;
        color: $color-vivid-blue;
        display: flex;
        width: 100%;
        overflow: hidden;
        position: relative;

        @media ($mobile-screen) {
            flex-direction: column;
        }

        .swipe-layer {
            z-index: 2;
            pointer-events: none;
        }

        .title {
            width: 25%;
            height: 100%;
            text-align: left;
            background-color: $color-light-gray;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $color-blue;

            @media ($device-screen) {
                width: 50%;
            }

            @media ($mobile-screen) {
                width: 100%;
                height: 400px;
            }

            h1 {
                align-self: center;

                @media ($mobile-screen) {
                    margin-top: 30px;
                }
            }
        }

        ul {
            display: flex;
            width: 75%;
            height: 100%;

            @media ($device-screen) {
                flex-direction: column;
                width: 50%;
            }

            @media ($mobile-screen) {
                width: 100%;
            }
        }

        li {
            width: 33.33%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            position: relative;

            @media ($device-screen) {
                width: 100%;
            }

            @media ($mobile-screen) {
                height: 275px;
            }

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: $color-light-gray;
                transition: opacity 0.01s linear;
                z-index: -1;

                @media ($device-screen) {
                    opacity: 0;
                }
            }

            .hover {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -1;
                transition: opacity 0.55s $primary-ease;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 0;
                
                @media ($device-screen) {
                    opacity: 1;
                }

                &.email {
                    background-image: url('/img/contact/contact_email_bg.jpg');
                }

                &.call {
                    background-image: url('/img/contact/contact_call_bg.jpg');
                }

                &.connect {
                    background-image: url('/img/contact/contact_connect_bg.jpg');
                }
            }

            span {
                &:after {
                    content: '';
                    display: block;
                    width: 250px;
                    height: 1px;
                    background-color: $color-gray;
                    margin: 30px auto;
                    transition: background-color 0.55s $primary-ease;

                    @media ($mobile-screen) {
                        margin: 15px auto;
                        background-color: rgba(white, 0.2);
                    }
                }
            }

            a {
                color: $color-blue;

                @media ($mobile-screen) {
                    color: white;
                }
            }

            &:hover {
                a {
                    color: white;
                }

                span {
                    &:after {
                        background-color: rgba(white, 0.2);
                    }
                }

                &:before {
                    transition: opacity 0.01s linear 0.55s;
                    opacity: 0;
                }

                .hover {
                    opacity: 1;
                }
            }
        }
    }

    .maps {

        h1 {
            margin: 80px 0 100px;

            @media ($mobile-screen) {
                margin: 40px 0 50px;
            }
        }

        .offices {
            display: flex;
            justify-content: space-between;

            @media ($device-screen) {
                flex-direction: column;
            }
        }

        .office {
            width: calc(50% - 55px);
            display: flex;
            flex-direction: column;

            @media ($device-screen) {
                width: 100%;
            }
        }

        .info {
            display: flex;
            box-sizing: border-box;
            transition: background-color 1s $primary-ease;

            @media ($mobile-screen) {
                flex-direction: column;
            }

            h2 {
                color: $color-blue;
            }

            .button-default {
                @media ($mobile-screen) {
                    align-self: center;
                }
            }
        }

        .device-only {
            margin-top: 40px;
        }

        .detail {
            flex: 2;
            align-self: center;
            display: flex;
            flex-direction: column;
            margin-bottom: 110px;
            margin-left: 100px;

            @media ($mobile-screen) {
                margin: 30px 0;
                align-self: flex-start;
            }
        }

        .address {
            margin-bottom: 20px;
        }

        .map {
            height: 400px;

            @media ($tablet-screen) {
                margin-bottom: 80px;
            }

            @media ($mobile-screen) {
                margin-bottom: 40px;
            }
        }
    }

    .affiliations {
        h1 {
            margin: 130px 0 70px;

            @media ($mobile-screen) {
                margin: 30px 0 40px;
            }

            @media ($tablet-screen) {
                margin: 0 0 70px;
            }
        }

        .gpr {
            display: flex;
            margin-bottom: 140px;

            @media ($device-screen) {
                flex-direction: column;
            }
        }

        .logo {
            margin-right: 145px;

            @media ($device-screen) {
                margin: 0 0 40px;
            }
        }

        .logo-container {
            background-color: $color-light-gray;
            padding: 38px 34px;
            text-align: center;
        }

        .button-underline {
            display: inline-block;
            margin-top: 50px;
            
            &:after {
                background-color: black;
            }
        }
    }
}